<template>
  <div class="mapTips">
    <div class="mask"></div>
    <div class="block"><span><i class="green"></i>{{$t('onlineMonitor.statusTip1')}}</span><span><i class="blue"></i> {{$t('onlineMonitor.statusTip2')}}</span><span><i
          class="red"></i>{{$t('onlineMonitor.statusTip3')}}</span><span><i class="gray"></i>{{$t('onlineMonitor.statusTip4')}}</span><span><i class="yellow"></i>{{$t('onlineMonitor.statusTip5')}}</span><span><i
          class="purple"></i>{{$t('onlineMonitor.statusTip6')}}</span></div>
  </div>
</template>

<script>
  export default {
    computed: {

    },
    watch: {

    },
    data() {
      return {

      }
    },
    methods: {

    }
  }

</script>

<style lang="scss" scoped>

</style>
