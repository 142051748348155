<template>
  <span>
    <el-dialog :title="$t('dialogCellAndLbs.name')" width="1200px" :visible.sync="visible" :before-close="close" custom-class="v-dialog v-dialog--highLight" append-to-body>
      <div class="container" ref="container"></div>
      <div class="top">
        <!-- <img :src="position.VehicleImageUrl"/> 车辆位置 -->
         <span style="position:relative"><svg class="icon" style="font-size:24px;"><use xlink:href="#icon-icon-gps--main"></use></svg>
         <!-- icon-hangtiantubiao- -->
         <svg class="icon" style="font-size:13px;position:absolute;left: 5px; top: 2px;color:#FFF;"><use xlink:href="#icon-hangtiantubiao-"></use> </svg></span>
         {{$t('dialogCellAndLbs.gpsPoint')}}
        <i-icon name="icon-jizhan--main"></i-icon> {{$t('dialogCellAndLbs.lbsCenter')}}
        <i-icon class="iml-0" name="icon-icon-wifi--main"></i-icon> {{$t('dialogCellAndLbs.wifiPoint')}}
        <!-- <i-icon class="iml-0" name="icon-jizhan--secondary"></i-icon> 基站辅助定位点
        <i-icon class="iml-0" name="icon-icon-wifi--secondary"></i-icon> WIFI辅助定位点 -->

      </div>
      <div class="bottom" v-if="false">
        <el-button class="flat" type="primary">总览</el-button>
        <el-button class="flat" type="primary">GPS位置</el-button>
        <el-button class="flat" type="primary">基站位置</el-button>
        <el-button class="flat" type="primary">WIFI位置</el-button>
      </div>
    </el-dialog>
    <slot name="reference"></slot>
  </span>
</template>

<script>
import { remoteLoad } from '@/common/utils'
import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
export default {
  data() {
    return {
      fullScreen: true,
      visible: false,
      position: {},
      gpsTime: '',
      rcvTime: '',
      hasGPS: false,
      type: 'gps',
      cellDistance: null,
      wifiDistance: null
    }
  },
  mixins: [mixinMapCachePosition],
  created() {
    this.overLayGPS = []
    this.overLayWIFI = []
    this.overLayCELL = []
  },
  methods: {
    open(position, gpsTime = '', rcvTime = '', type = 'gps') {

      this.position = position
      this.visible = true
      this.gpsTime = gpsTime
      this.rcvTime = rcvTime
      this.type = type

      console.log(position, type)
      this.$nextTick(() => {
        this.initMap()
        this.addMarkers()
      })
    },
    close(done) {
      this.removeMarkers()
      this.map.destroy()
      this.infoWindow = null
      done()
    },
    async initMap(center = [116.397428, 39.90923], zoom = 11) {
      const position = this.loadPosition()
      center = position.center
      zoom = position.zoom

      if (!window.AMap) {
        await remoteLoad(this.$AMAP_URL)
      }
      if (!window.AMapUI) {
        await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
      }
      let map = new window.AMap.Map(this.$refs.container, {
        resizeEnable: true,
        zoom,
        center,
        zooms: [3,20],
        expandZoomRange: true
      })

      this.map = map
      this.AMap = window.AMap

      //this.initSimpleMarker()
      this.ifNotExistsWindow()

      window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
        map.addControl(new BasicControl.LayerSwitcher({
          position: {
            top: '50px',
            right: '10px'
          }
        }))
      })
      // await this.initRealScene()
    },
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        let infoWindow = new this.AMap.InfoWindow({
          isCustom: true,
          content: '',
          offset: new this.AMap.Pixel(0, -42)
        })
        this.infoWindow = infoWindow
      }
    },
    getPanel(name, lng, lat, time = '') {
      let panel = this.$dataPanel.create({
        bgColor: true,
        lon: lng,
        lat: lat,
        title: name,
        paddingLeft: '5em',
        showMore: false,
        titleBackground: '#409EFF',
        close: true,
        showAddrDefault: true,
        datas: [
          { title: '定位时间：', text: time }
        ],
        onClose: () => {
          this.map.clearInfoWindow()
        },
      })
      return panel
    },
    // 添加GPS车辆Marker、LBSMarker、WifiMarker
    addMarkers() {
      this.addGPS()
      this.addWifi()
      this.addCell()
      this.map.setFitView()
    },
    removeMarkers() {
      //this.removeGPS()
      this.removeWifi()
      this.removeCell()
    },
    addGPS() {
      this.hasGPS = true
      if (!this.position.Lon || !this.position.Lat) {
        this.hasGPS = false
        return
      }
      let size = `<span style="position:relative"><svg class="icon" style="font-size:30px;"><use xlink:href="#icon-icon-gps--main"></use></svg><svg class="icon" style="font-size:16px;position:absolute;left: 7px; top: -8px;color:#FFF;"><use xlink:href="#icon-hangtiantubiao-"></use> </svg></span>`
      let pixel = new this.AMap.Pixel(-17, -31)
      if (this.type == 'gps') {
        size = `<span style="position:relative"><svg class="icon" style="font-size:48px;"><use xlink:href="#icon-icon-gps--main"></use></svg><svg class="icon" style="font-size:20px;position:absolute;left: 14px; top: -18px;color:#FFF;"><use xlink:href="#icon-hangtiantubiao-"></use> </svg></span>`
        pixel = new this.AMap.Pixel(-25, -42)
      }
      let marker = new this.AMap.Marker({
        map: this.map,
        content: size,
        position: [this.position.Lon, this.position.Lat],
        offset: pixel,
        //angle: this.position.Direct,
        zIndex: 101
      })
      this.overLayGPS.push(marker)
      marker.on('click', () => {
        const panel = this.getPanel(this.$t('dialogCellAndLbs.gpsPoint'), this.position.gps.sCenter.Lng, this.position.gps.sCenter.Lat, this.gpsTime)
        this.infoWindow.setContent(panel)
        this.infoWindow.open(this.map, marker.getPosition())
      })
    },
    removeGPS() {
      this.overLayGPS.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.overLayGPS.splice(0)
    },
    addWifi() {
      if (!this.position.wifi.points || this.position.wifi.points.length === 0) {
        return
      }

      // this.position.wifi.points.forEach(k => {
      //   let marker = new this.AMap.Marker({
      //     map: this.map,
      //     position: [k.Lng, k.Lat],
      //     content: `<svg class="icon" style="font-size:32px"><use xlink:href="#icon-icon-wifi--secondary"></use></svg>`,
      //     offset: new this.AMap.Pixel(-12, -12)
      //   })
      //   this.overLayWIFI.push(marker)
      //   // let circle = new this.AMap.Circle({
      //   //   map: this.map,
      //   //   strokeColor:'#409EFF',
      //   //   strokeWeight: 1,
      //   //   fillColor:'rgba(0,0,255,1)',
      //   //   fillOpacity:0.1,
      //   //   center: [k.Lng, k.Lat],
      //   //   radius: k.Radius
      //   // })
      //   // this.overLayWIFI.push(circle)
      //   marker.on('click', () => {
      //     const panel = this.getPanel('WIFI辅助定位点', marker.getPosition().getLng(), marker.getPosition().getLat())
      //     this.infoWindow.setContent(panel)
      //     this.infoWindow.open(this.map, marker.getPosition())
      //   })
      // })
      let size = '30px'
      let pixel = new this.AMap.Pixel(-17, -31)
      if (this.type == 'wifi') {
        size = '48px'
        pixel = new this.AMap.Pixel(-25, -42)
      }
      let marker = new this.AMap.Marker({
        map: this.map,
        position: [this.position.wifi.center.Lng, this.position.wifi.center.Lat],
        content: `<svg class="icon" style="font-size:${size}"><use xlink:href="#icon-icon-wifi--main"></use></svg>`,
        offset: pixel
      })
      this.overLayWIFI.push(marker)

      marker.on('click', () => {
        const panel = this.getPanel(this.$t('dialogCellAndLbs.wifiPoint'), this.position.wifi.sCenter.Lng, this.position.wifi.sCenter.Lat, this.rcvTime)
        this.infoWindow.setContent(panel)
        this.infoWindow.open(this.map, marker.getPosition())
      })

      if (this.hasGPS) {
      // 画线
        /*let polyline =*/ new this.AMap.Polyline({
          map: this.map,
          borderWeight: 2,
          path: [marker.getPosition(), new this.AMap.LngLat(this.position.Lon, this.position.Lat)],
          strokeColor: '#259ED6',
          lineCap: 'round'
        })

        let distance = this.AMap.GeometryUtil.distance(marker.getPosition(), new this.AMap.LngLat(this.position.Lon, this.position.Lat));
        /*let polyline_text =*/ new this.AMap.Text({
            map: this.map,
            position: [(marker.getPosition().getLng() + this.position.Lon) / 2, (marker.getPosition().getLat() + this.position.Lat) / 2],
            textAlign: 'right',
            offset: new this.AMap.Pixel(-20, -12),
            topWhenClick: true,
            text: this.$t('dialogCellAndLbs.distance') + this.formatDistance(parseInt(distance, 10))
        });
        this.wifiDistance = parseInt(distance, 10);
      }

    },
    removeWifi() {
      this.overLayWIFI.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.overLayWIFI.splice(0)
    },
    addCell() {
      if (!this.position.cell.points || this.position.cell.points.length === 0) {
        return
      }

      // this.position.cell.points.forEach(k => {
      //   let marker = new this.AMap.Marker({
      //     map: this.map,
      //     position: [k.Lng, k.Lat],
      //     content: `<svg class="icon" style="font-size:32px"><use xlink:href="#icon-jizhan--secondary"></use></svg>`,
      //     offset: new this.AMap.Pixel(-12, -12)
      //   })
      //   this.overLayCELL.push(marker)
      //   let circle = new this.AMap.Circle({
      //     map: this.map,
      //     strokeColor:'#409EFF',
      //     strokeWeight: 1,
      //     fillColor:'rgba(0,0,255,1)',
      //     fillOpacity:0.05,
      //     center: [k.Lng, k.Lat],
      //     radius: k.Radius
      //   })
      //   this.overLayCELL.push(circle)

      //   marker.on('click', () => {
      //     const panel = this.getPanel('基站辅助定位点', marker.getPosition().getLng(), marker.getPosition().getLat())
      //     this.infoWindow.setContent(panel)
      //     this.infoWindow.open(this.map, marker.getPosition())
      //   })
      // })
      let size = '30px'
      let pixel = new this.AMap.Pixel(-17, -31)
      if (this.type == 'lbs') {
        size = '48px'
        pixel = new this.AMap.Pixel(-25, -42)
      }
      let marker = new this.AMap.Marker({
        map: this.map,
        position: [this.position.cell.center.Lng, this.position.cell.center.Lat],
        content: `<svg class="icon" style="font-size:${size}"><use xlink:href="#icon-jizhan--main"></use></svg>`,
        offset: pixel
      })
      this.overLayCELL.push(marker)


      marker.on('click', () => {
        const panel = this.getPanel(this.$t('dialogCellAndLbs.lbsCenter'), this.position.cell.sCenter.Lng, this.position.cell.sCenter.Lat, this.rcvTime)
        this.infoWindow.setContent(panel)
        this.infoWindow.open(this.map, marker.getPosition())
      })

      if (this.hasGPS) {
      // 画线
      /*let polyline =*/ new this.AMap.Polyline({
          map: this.map,
          borderWeight: 2,
          path: [marker.getPosition(), new this.AMap.LngLat(this.position.Lon, this.position.Lat)],
          strokeColor: '#FF5722',
          lineCap: 'round'
        })
      
        let distance = this.AMap.GeometryUtil.distance(marker.getPosition(), new this.AMap.LngLat(this.position.Lon, this.position.Lat));
        /*let polyline_text =*/ new this.AMap.Text({
            map: this.map,
            position: [(marker.getPosition().getLng() + this.position.Lon) / 2, (marker.getPosition().getLat() + this.position.Lat) / 2],
            textAlign: 'right',
            offset: new this.AMap.Pixel(-20, -12),
            topWhenClick: true,
            text: this.$t('dialogCellAndLbs.distance')+ ' ' + this.formatDistance(parseInt(distance, 10))
        });
        this.cellDistance = parseInt(distance, 10);
      }
      
      if (this.position.wifi.points && this.position.wifi.points.length) {
        new this.AMap.Polyline({
          map: this.map,
          borderWeight: 2,
          path: [marker.getPosition(), new this.AMap.LngLat(this.position.wifi.center.Lng, this.position.wifi.center.Lat)],
          strokeColor: '#FF5722',
          lineCap: 'round'
        })
        let distance = this.AMap.GeometryUtil.distance(marker.getPosition(), new this.AMap.LngLat(this.position.wifi.center.Lng, this.position.wifi.center.Lat));
        /*let polyline_text =*/ new this.AMap.Text({
            map: this.map,
            position: [(marker.getPosition().getLng() + this.position.wifi.center.Lng) / 2, (marker.getPosition().getLat() + this.position.wifi.center.Lat) / 2],
            textAlign: 'right',
            offset: new this.AMap.Pixel(-20, -12),
            topWhenClick: true,
            text: this.$t('dialogCellAndLbs.distance')+ ' ' + this.formatDistance(parseInt(distance, 10))
        });
      }

    },
    removeCell() {
      this.overLayCELL.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.overLayCELL.splice(0)
    },
    formatDistance(distance) {
      if (distance < 1000) {
        return distance + ' m'
      } else {
        const kilometre = (distance / 1000).toFixed(2)
        return kilometre + ' km'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables-simple.scss";
.container {
  height: 800px;
  max-height: calc(100vh - 150px);
  &.full-screen {
    height: calc(100vh - 40px);
  }
  & ::v-deep .amap-overlay-text-container {
    font-size: 12px;
    background-color: #FFF;
    border-color:#1E88E5;
    padding: 4px 4px;
    //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.top {
  position: absolute;
  height: 32px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #FFF;
  opacity: 0.9;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  .icon {
    font-size: 24px;
  }
}
.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #FFF;
  opacity: 0.9;
}
</style>

