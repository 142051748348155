import { render, staticRenderFns } from "./mapTips.vue?vue&type=template&id=1590d20c&scoped=true&"
import script from "./mapTips.vue?vue&type=script&lang=js&"
export * from "./mapTips.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1590d20c",
  null
  
)

export default component.exports