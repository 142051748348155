<template>
  <el-dropdown trigger="click" @command="select" v-if="true">
    <slot>
      <span class="el-dropdown-link" v-if="currentMap===Google || currentMap===Google2" :key="Google">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/GoogleMaps_logo.svg" class="mapLogo">{{$t('changeMap.google')}}
      </span>
      <span class="el-dropdown-link" v-else-if="currentMap===Openstreet" :key="Openstreet">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/openstreet.png" class="mapLogo">Openstreet
      </span>
      <span class="el-dropdown-link" v-else-if="currentMap===Bmap" :key="Bmap">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/BMap.png" class="mapLogo">{{$t('changeMap.bmap')}}
      </span>
      <span class="el-dropdown-link" v-else-if="currentMap===QQmap" :key="QQmap">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/QQMap.png" class="mapLogo">{{$t('changeMap.qqmap')}}
      </span>
      <span class="el-dropdown-link" v-else-if="currentMap===Bing" :key="Bing">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/bing.png" class="mapLogo">{{$t('changeMap.bing')}}
      </span>
      <span class="el-dropdown-link" v-else :key="Amap">
        <i-icon name="icon-menu-down"></i-icon>
        <img src="../../assets/icon/amap_logo.png" class="mapLogo">{{$t('changeMap.amap')}}
      </span>
    </slot>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :command="Amap"> <img src="../../assets/icon/amap_logo.png" class="mapLogo">{{$t('changeMap.amap')}}</el-dropdown-item>
      <el-dropdown-item :command="Bmap"> <img src="../../assets/icon/BMap.png" class="mapLogo">{{$t('changeMap.bmap')}}</el-dropdown-item>
      <el-dropdown-item :command="QQmap"> <img src="../../assets/icon/QQMap.png" class="mapLogo">{{$t('changeMap.qqmap')}}</el-dropdown-item>
      <el-dropdown-item :command="Google"> <img src="../../assets/icon/GoogleMaps_logo.svg" class="mapLogo">{{$t('changeMap.google')}}</el-dropdown-item>
      <!-- <el-dropdown-item :command="Google2"> <img src="../../assets/icon/GoogleMaps_logo.svg" class="mapLogo">{{$t('changeMap.google')}}2</el-dropdown-item> -->
      <el-dropdown-item :command="Openstreet"> <img src="../../assets/icon/openstreet.png" class="mapLogo">Openstreet</el-dropdown-item>
      <el-dropdown-item :command="Bing"> <img src="../../assets/icon/bing.png" class="mapLogo">{{$t('changeMap.bing')}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import { setDefaultMap } from '@/common/cache'
import { setCommonParam } from '@/api/config'
const Google = 'Google'
const Google2 = 'Google2'
const Amap = 'Amap'
const Openstreet = 'Openstreet'
const Bmap = 'Bmap'
const QQmap = 'QQmap'
const Bing = 'Bing'
export default {
  data() {
    return {
      Google,
      Google2,
      Amap,
      Bmap,
      QQmap,
      Openstreet,
      Bing
    }
  },
  methods: {
    select(val) {
      setDefaultMap(val)
      this.setCurrentMap(val)
      setCommonParam({
        current_map: val
      })
    },
    ...mapMutations({
      'setCurrentMap': 'SET_CURRENT_MAP'
    })
  },
  computed: {
    ...mapGetters([
      'currentMap'
    ])
  }
}
</script>

<style lang="scss" scoped>
.mapLogo {
  height: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
}
</style>
