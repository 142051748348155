<template>
  <ul class="deviceTotals">
    <li class="left">
      <div class="h4">
        <img src="../../../assets/images/gs-h4.png" alt="" class="tag">
        <div class="i"> <i>{{ totalCount }} </i><strong>设备总数</strong></div>
        <!-- <div class="span"><span>{{$t('onlineMonitor.alamStatus3')}}</span></div> -->
      </div>
      <div class="msk"></div>
    </li>
    <li class="mid pointer" @click="hanlderAll">
      <div class="h4">
        <img src="../../../assets/images/gs-h3.png" alt="" class="tag">
        <div class="i"> <i>{{ onlineCount }} </i><strong>在线数</strong></div>
        <!-- <div class="span"><span>{{$t('onlineMonitor.alamStatus1')}}</span></div> -->
      </div>
      <div class="msk"></div>
    </li>
    <li class="right">
      <div :class="alarmCount ? 'h4 pointer' : 'h4'">
        <template v-if="alarmCount">
          <img src="../../../assets/images/gs-h22.gif" alt="" class="tag" v-if="playSound"
            @click="playSound = !playSound">
          <img src="../../../assets/images/gs-h2.png" alt="" class="tag" v-else @click="playSound = !playSound">
        </template>
        <template v-else>
          <img src="../../../assets/images/gs-h2.png" alt="" class="tag">
        </template>
        <div class="i" @click="enterAlarm"> <i>{{ alarmCount }} </i> <strong>报警数</strong></div>
        <div class="span" v-show="false"><span @click="enterAlarm">
            <i-icon name="iconxinxiguanli_weibaojing" :class="alarmCount ? 'alarm' : ''"></i-icon>
          </span>
          <div class="img">
            <img v-if="playSound" @click="playSound = !playSound" src="../../../assets/icon/sound_on.gif"
              style="cursor:pointer;" />
            <img v-else @click="playSound = !playSound" src="../../../assets/icon/sound_off.png"
              style="cursor:pointer;" />
          </div>
          <audio :src="`${DOWNLOAD_URL}static/file/Alarm.wav`" loop ref="alarmSound"></audio>
        </div>
      </div>
      <div class="msk"></div>
    </li>
  </ul>
</template>

<script>
import {
  DOWNLOAD_URL
} from '@/api/config'
import {
  setPlaySound,
  getPlaySound
} from '@/common/cache'
import {
  QueryVehicleSummarCount,
} from '@/api/common'
export default {
  props: {
    upgrade: {
      type: Number,
      default: 15
    },
  },
  watch: {
    upgrade(n, o) {
      if (n == 1) {
        this.fetchSummar()
      }
    },
    playSound(val) {
      setPlaySound(val)
      if (val && this.alarmCount > 0) {
        // 播放报警
        this.$refs.alarmSound.play()
      } else {
        // 停止报警
        this.$refs.alarmSound.pause()
      }
    },
    alarmCount(val) {
      if (val > 0 && this.playSound) {
        // 播放报警
        this.$refs.alarmSound.play()
      } else {
        // 停止报警
        this.$refs.alarmSound.pause()
      }
    }
  },
  data() {
    return {
      DOWNLOAD_URL,
      totalCount: 0,
      onlineCount: 0,
      alarmCount: 0,
      playSound: false
    }
  },
  methods: {
    fetchSummar() {
      QueryVehicleSummarCount().then(ret => {
        this.alarmCount = ret.data.alarmCount
        this.totalCount = ret.data.totalCount
        this.onlineCount = ret.data.onlineCount
      })
    },
    enterAlarm() {
      this.$emit('enter-alarm')
      this.$root.$emit('pop-alarm:show')
    },
    hanlderAll() {
      this.$emit('handlerAll',1)
    }
  },
  created() {
    this.playSound = getPlaySound(false)
    this.fetchSummar()
  },
}

</script>

<style lang="scss" scoped>
.deviceTotals {
  position: absolute;
  left: 50%;
  margin-left: -280px;
  bottom: 35px;
  z-index: 3;
  display: flex;

  li {
    margin-left: 5px;
    padding-left: 15px;
    width: 183px;
    height: 70px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .h4 {
      display: flex;
      font-size: 16px;
      padding-top: 1px;
      padding-right: 4px;

      .tag {
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 12px;
        height: 35px;
        width: 35px;
      }

      .i {
        text-align: center;
        padding-right: 10px;
        line-height: 36px;
        font-size: 24px;
        margin-top: 8px;
        letter-spacing: 0px;

        i {
          display: block;
          width: 100%;
          height: 24px;
          font-style: normal;
          font-stretch: normal;
        }

        strong {
          display: block;
          width: 100%;
          font-size: 14px;
          color: #CFCFCF;
        }
      }

      .span {
        padding: 0 15px;
        height: 32px;
        line-height: 32px;
        margin-top: 1px;
        color: #fff;
        background-color: #4d8337;
        border-radius: 16px;
      }
    }

    .msk {
      width: 100%;
      height: 100%;
      background: url(../../../assets/images/gs-h1.png) no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .alarm {
    animation: fade 1s infinite ease-in-out;
    -webkit-animation: fade 1s infinite ease-in-out;
  }

  .mid {
    .h4 {
      .i {
        color: #FFFFFF;

        i {
          color: #3AA153;
        }
      }

      .span {
        background-color: #4d8337;
      }

      .span:hover {
        background-color: #3f6230;
      }
    }

  }

  .left {

    .h4 {
      .i {
        color: #FFFFFF;
      }

      .span {
        min-width: 32px;
        padding: 0 10px;
        text-align: center;
        font-size: 22px;
        background-color: #ff4141;
        display: flex;

        span {
          margin-right: 10px;
        }
      }

      .span:hover {
        background-color: #dd3434;
      }

      .img {
        margin-top: 7px;
        height: 20px;
        border-left: 1px solid #fff;

        img {
          display: block;
          margin-left: 7px;
        }
      }
    }

  }

  .right {
    .h4 {
      .i {
        color: #FFFFFF;

        i {
          color: #DE4930;
        }
      }

      .span {
        background-color: #32435f;
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 1.0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1.0;
  }
}
</style>
