<template>
  <el-button v-if="visible" @click="open" type="primary" class="flat primary--text">
    <i class="el-icon-search"></i>{{$t('onlineMonitor.favoritePlace')}}
  </el-button>
  <div style="white-space:nowrap;" v-else>
    <div class="v-split-v"></div>
    <span>
      <el-button @click="close" icon="el-icon-back" type="info" class="flat"></el-button>
    </span>
    <el-autocomplete
      v-model="key"
      autofocus
      clearable
      ref="input"
      :placeholder="$t('onlineMonitor.favoritePlaceTip')"
      :fetch-suggestions="getData"
      @select="select"
      >
    </el-autocomplete>
    <span>
      <tool-tip :content="$t('common.addMarkers')" :open-dealy="1500" type="grey-aphla">
        <el-button icon="el-icon-circle-plus" type="info" class="flat" @click="$emit('save')"></el-button>
      </tool-tip>
      <tool-tip v-if="show && showBtn" :content="$t('common.joinAll')" :open-dealy="1500" type="grey-aphla">
        <el-button icon="el-icon-location" :type="`${showInner?'primary':'info'}`" class="flat" @click="show"></el-button>
      </tool-tip>
      <tool-tip :content="$t('common.clears')" :open-dealy="1500" type="grey-aphla">
        <el-button icon="el-icon-delete" type="info" class="flat" @click="$emit('delete')"></el-button>
      </tool-tip>
    </span>
    <div class="v-split-v"></div>
  </div>
</template>

<script>
import { GetPOI } from '@/api/common'
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    visible() {
      return this.label !== this.value
    }
  },
  watch: {
    value(val) {
      this.valueInner = val
    },
    valueInner(val, oldVal) {
      if (val === oldVal) return
      this.$emit('input', val)
    }
  },
  data() {
    return {
      valueInner: this.value,
      showInner: false,
      key: ''
    }
  },
  methods: {
    getData(queryString, cb) {
      const promise = new Promise((resolve) => {
        GetPOI(queryString).then(ret => {
          const list = ret.data.slice()
          list.forEach(k => {
            k.value = k.PointName
          })
          cb(list)
          resolve()
        })
      })
      return promise
    },
    select(item) {
      this.$emit('select', item)
    },
    open() {
      this.key = ''
      this.valueInner = this.label
      setTimeout(() => {
        this.$refs.input.focus()
      }, 300)
    },
    close() {
      this.valueInner = null
    },
    show() {
      this.showInner = !this.showInner
      this.$emit('show')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/variables-simple.scss";
.el-button {
  font-size: 14px;
  padding: 4px;
  margin-left: 10px;
  overflow: hidden;
  display: inline-block;
}
.el-autocomplete {
  margin-left: 10px;
}

.v-split-v {
  display: inline-block;
  height: 18px;
  width: 1px;
  background-color: $--color-primary;
  vertical-align: middle;
  margin-left: 10px;
}
</style>

