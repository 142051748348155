<template>
  <div>
    <el-input v-model="key" :placeholder="$t('onlineMonitor.searchPlace')" prefix-icon="el-icon-search" size="small" @click.native.prevent.stop @focus="visible=true" @keydown.native.enter="selectItem" clearable
    @keydown.native.up.stop="preIndex" @keydown.native.down.stop="nextIndex" ref="input" class="search-input"></el-input>
    <p v-if="loading" style="text-align:center; line-height: 28px;"><i  class="el-icon-loading" /></p>
    <el-scrollbar wrap-style="max-height:200px;" v-show="visible" style="border:1px solid #ccc; border-top: 0;background:#fff;" >
      <label class="result-label" v-for="(item,index) in list" ref="labelItems" :key="index" @click.stop="clickItem(item)" :class="{'active': selectedIndex == index}">{{item.name}}<span>{{item.district}}</span></label>
      <label class="result-label" v-if="!list.length && key">
        <p style="color: #999;text-align:center;line-height: 48px;">{{$t('commonPanel.noPlaceTip')}}</p>
      </label>
    </el-scrollbar>
  </div>
</template>


<script>
import { remoteLoad, debounce } from '@/common/utils'
import { GetGeoCode } from '@/api/common'
export default {
  data() {
    return {
      key: '',
      list: [],
      visible: false,
      selectedIndex: null,
      loading: false
    }
  },
  watch: {
    key() {
      this.keyChangeHandle()
    },
    visible(val) {
      if (val) {
        window.addEventListener('click', this.closeList)
      } else {
        window.removeEventListener('click', this.closeList)
      }
    }
  },
  methods: {
    initAutocomplete() {
      this.autoComplete = new window.AMap.Autocomplete({city: '全国'})
    },
    promise1() {
      return new Promise(resolve => {
        this.selectedIndex = null
        this.autoComplete.search(this.key, (status, result) => {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info === 'OK') {
            resolve(result.tips)
          } else {
            resolve([])
          }
        })
      })
    },
    promise2() {
      return new Promise(resolve => {
        GetGeoCode(this.key, '全国').then(ret => {
          if (ret.data.status == '1') {
            let list = ret.data.geocodes.slice()
            list.forEach(k => {
              k.location = new window.AMap.LngLat(k.location.split(',')[0], k.location.split(',')[1])
              k.name = k.formatted_address
            })
            resolve(list)
          }
        }).catch(() => {
          resolve([])
        })
      })
    },
    keyChange() {
      if (!this.key) {
        this.list = []
        return
      }

      this.loading = true
      Promise.all([this.promise2(), this.promise1()]).then(lists => {
        let result = []
        this.loading = false
        lists.forEach(k => result = result.concat(k))
        this.list = result
        this.visible = true
      })
    },
    clickItem(item) {

      this.selectedIndex = null

      if (!item.location) {
        this.$message(this.$t('commonPanel.noPlaceTip'))
        return
      }

      this.$emit('select', item.location.getLng(), item.location.getLat())
      this.visible = false
    },
    closeList() {
      this.visible = false
    },
    preIndex() {
      if (this.selectedIndex == null) {
        this.selectedIndex = this.list.length - 1
      } else if (this.selectedIndex <= 0) {
        this.selectedIndex = this.list.length - 1
      } else {
        this.selectedIndex--
      }

      this.$refs.labelItems[this.selectedIndex].scrollIntoViewIfNeeded()
    },
    nextIndex() {
      if (this.selectedIndex == null) {
        this.selectedIndex = 0
      } else if (this.selectedIndex >= this.list.length - 1) {
        this.selectedIndex = 0
      } else {
        this.selectedIndex++
      }

      this.$refs.labelItems[this.selectedIndex].scrollIntoViewIfNeeded()
    },
    selectItem() {
      if (this.selectedIndex != null) {
        this.clickItem(this.list[this.selectedIndex])
        this.$refs.input.blur()
      }
    }
  },
  created() {
    this.keyChangeHandle = debounce(this.keyChange, 300)
  },
  mounted() {
    if (!window.AMap) {
      remoteLoad(this.$AMAP_URL).then(this.initAutocomplete)
    } else {
      this.initAutocomplete()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input{
  & ::v-deep .el-input__inner{
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(181,181,181,.4);
  }
}
.result-label {
  color: rgba(0, 0, 0, .56);
  font-size: 13px;
  line-height: 24px;
  display: block;
  padding: 0 12px;

  &:hover, &.active {
    background-color: #cae1ff;
    cursor: pointer;
  }
  span {
    padding-left: 4px;
    color: rgba(0, 0, 0, .26);
  }
}
</style>
