import {CancelAlarm as api} from '@/api/command'

export function CancelAlarm(vm, objectId) {
  return new Promise((resolve, reject) => {
    vm.$prompt(vm.$t('other.cancelAlarlText'), vm.$t('other.cancelAlarm'), {
      confirmButtonText: vm.$t('common.ok'),
      cancelButtonText: vm.$t('common.cancel'),
    }).then(({value}) => {
      api(objectId, value || '')
      .then(ret => {
        if (ret.data.errCode) {
          vm.$message({
            message: ret.data.errMsg,
            type: 'error'
          })
          reject()
        } else {
          vm.$message({
            message: vm.$t('other.sendSuccess'),
            type: 'success'
          })
          resolve()
        }
      })
      .catch(err => {
        vm.$message({
          message: err,
          type: 'error'
        })
        reject(err)
      })
    }).catch(() => {
      reject()
    })
  })

}
