import {
  getMonitorCache,
  setMonitorCache,
  getCustomSetting
} from '@/common/cache'
import {
  localTime,
  translateFR,
  translateGps
} from '@/common/utils'
import {
  mapGetters
} from 'vuex'
import {
  GetObjectIdsByVehicleIDs,
  AddStar,
  DeleteStar
} from '@/api/common'
import {
  CancelAlarm
} from '@/common/other'
import miment from 'miment'
import {
  getDirectionDescription
} from '@/common/dictionary'
import * as API from '@/api/common'
// const DetailVehicle = require('@/views/manage/detail/detail-vehicle' + process.env.VUE_APP_VRC + '.vue').default
const DetailVehicle = require('@/router/components' + process.env.VUE_APP_VRC).DetailVehicle
const DetailDevice = require('@/router/components' + process.env.VUE_APP_VRC).DetailDevice
import MapPlace from '@/views/common/map-place'

const REFRESH_BTN_MIN_TIME = 2
export default {
  created() {

    const mixinMonitorCache = Object.assign({
      tableShow: false,
      tableWrap: false,
      isShowAddressDefault: true
    }, getMonitorCache())

    if (this.table) {
      this.table.show = mixinMonitorCache.tableShow
      this.table.isWrap = mixinMonitorCache.tableWrap
    }

    this.isShowAddressDefault = mixinMonitorCache.isShowAddressDefault

    if (!this.monitorLeft) {
      // 不显示菜单时隐藏左侧列表
      this.isNavShow = false
      // 不显示菜单时隐藏表格
      this.table.show = false
    }

    if (!this.hasMenu) {
      this.importantInfo = false
    } else if (!this.monitorLeft) {
      this.importantInfo = false
    }

    let viewItems = getCustomSetting('viewItems')
    let viewColumns = getCustomSetting('viewColumns')
    if (!viewItems) {
      viewItems = ['VehicleName']
    }
    if (!viewColumns) {
      viewColumns = ['VehicleName', 'Speed', 'Miles', 'isOnline', 'Acc', 'isAlarm', 'Status', 'GPSTime', 'RcvTime', 'Address']
    }

    this.viewItems = viewItems
    this.viewColumns = viewColumns
  },
  watch: {
    'table.show': {
      deep: true,
      handler(val) {
        const cache = getMonitorCache({})
        cache.tableShow = val
        setMonitorCache(cache)
      }
    },
    'table.isWrap': {
      deep: true,
      handler(val) {
        const cache = getMonitorCache({})
        cache.tableWrap = val
        setMonitorCache(cache)
      }
    },
    isShowAddressDefault(val) {
      const cache = getMonitorCache({})
      cache.isShowAddressDefault = val
      setMonitorCache(cache)
    },
    isFixedToolBar(val) {
      if (!this.LayerSwitcherControl) {
        return
      }
      if (val) {
        this.LayerSwitcherControl.domNodes.container[0].style.top = '8px'
      } else {
        this.LayerSwitcherControl.domNodes.container[0].style.top = '48px'
      }
    }
  },
  data() {
    return {
      refreshBtnBusy: false,
      flashNotAllow: false,
      importantInfo: true,
      iconSize: 38,
      currentCommandTracker: 0,
      tConfig: {
        poi: true,
        region: true,
        rect: true
      },
      infoWindow: null
    }
  },
  methods: {
    busyRefreshBtn() {
      this.refreshBtnBusy = true
      setTimeout(() => {
        this.refreshBtnBusy = false
      }, REFRESH_BTN_MIN_TIME * 1000)
    },
    checkFlash(el) {
      //this.flashNotAllow = !(el.querySelector('object:not([data-i])') != null)
    },
    getTextStyle(color) {
      let style = {
        '蓝色': {
          backgroundColor: '#1976D2',
          color: '#FFF',
        },
        '绿色': {
          backgroundColor: '#81C784',
          color: 'black',
          opacity: .9
        },
        '黄色': {
          backgroundColor: '#FBC02D',
          color: 'black'
        },
        '白色': {},
        '黑色': {
          backgroundColor: '#000',
          color: '#FFF'
        }
      } [color]
      if (!style) {
        style = {
          backgroundColor: color,
          color: '#000',
          borderColor: '#EEE'
        }
      }
      style = Object.assign({
        borderColor: '#1E88E5'
      }, style, {
        padding: '4px 4px'
      })
      return style
    },
    getTeamStyle(color) {
      let style = {
        backgroundColor:'#fff',
        color:'#000',
        borderColor: color||'#fff',
        borderWidth: '3px'
      }
      return style
    },
    startDrag() {
      this.infoWindow.close()
    },
    transferVID(vehicleIDs) {
      return new Promise((resolve, reject) => {
        GetObjectIdsByVehicleIDs(vehicleIDs, reject).then(ret => {
          if (!ret.data.errCode) {
            return resolve(ret.data.data)
          } else {
            console.log(ret.data.errMsg)
            return resolve([])
          }
        }).catch(err => reject(err))
      })
    },
    getVehicleName(row) {
      let VehicleName = row.TypeID === 1 ? row.PersonName : (row.TypeID === 2 ? row.ArtName : row.VehicleName)
      let arr = []
      let temp = {
        PeopleName: row.PeopleName,
        VehicleName: VehicleName,
        SIM2: row.SIM2,
        SIM: row.SIM,
        VIN: row.VIN,
        TrackerTypeName: this.$t('dict')[row.TrackerTypeName]
      }
      for (var p in temp) {
        if (this.viewItems.includes(p)) {
          arr.push(temp[p])
        }
      }
      arr = arr.filter(k => !!k)
      if (arr.length == 0) {
        arr = ['--']
      }
      return arr.join('-')
    },
    addDataToMap_getPanel(device) {
      // 如果在监控列表里面就取最新数据
      const index = this.list.findIndex(k => this.diffCompare(k, device))
      if (~index) {
        device = this.list[index].data
      }
      // 判断是有线设备还是无线设备
      let externalData = null
      if (device.TrackerType !== 0 || device.Battery > 0) {
        // 无线设备
        externalData = {
          trackerType: device.TrackerType,
          battery: device.Battery,
          batteryLife: device.BatteryLife,
          schedule: localTime(device.Schedule)
        }
      }
      let replceStatus = ''
      if (device.StatusDes.search(device.Schedule) > 0) {
        replceStatus = device.StatusDes.replace(new RegExp(device.Schedule, 'g'), localTime(device.Schedule))
      }
      if (this.$i18n.locale === 'fr') {
        replceStatus = translateFR(device.StatusDes)
      }
      let colorArr = ['#65c240', '#1469b8', '#ff4141', '#7f7f7f', '#ffd800', '#ac55eb']
      let titleBackground = colorArr[translateGps(device.TypeID, device, true) - 1]
      // 1：行驶2.熄火 3.静止/怠速 4.离线 5.报警 6.无参考定位

      let viewInfoFun = this.loginInfo.allFuns.includes(5042) || this.loginInfo.allFuns.includes(5044)
      let isVipImg = device.isVip ? `<img src="${require('../../assets/icon/vip.png')}" style="margin-left:8px;height:14px;vertical-align:sub;"/>` : ''

      let links = []

      if (viewInfoFun && this.importantInfo) {
        links.push({
          icon: 'iconxiangqing',
          text: this.$t('onlineMonitor.panelLink2')
        })
      }

      {
        // links.push({
        //   icon: 'iconquanjing',
        //   text: this.$t('onlineMonitor.panelLink3'),
        //   lang: true
        // })

        links.push({
          icon: 'iconguiji',
          text: this.$t('onlineMonitor.panelLink4')
        })

        // links.push({
        //   icon: 'iconzhuizong1',
        //   text: this.$t('onlineMonitor.panelLink12')
        // }) //定位

        // links.push({
        //   icon: 'icontaiya_xiao-01',
        //   text: this.$t('onlineMonitor.panelLink14')
        // })
      }

      // if (this.importantInfo) {
      //   if (device.SF) {
      //     links.push({
      //       icon: 'iconshefang',
      //       text: this.$t('onlineMonitor.panelLink9')
      //     })
      //   } else {
      //     links.push({
      //       icon: 'iconchefang',
      //       text: this.$t('onlineMonitor.panelLink8')
      //     })
      //   }
      // }

      if (device.isFocus) {
        links.push({
          icon: 'iconyishoucang',
          text: this.$t('onlineMonitor.panelLink11')
        })
      } else {
        links.push({
          icon: 'iconshoucang',
          text: this.$t('onlineMonitor.panelLink10')
        })
      }

      if (this.loginInfo.isUser && this.importantInfo && this.loginInfo.allFuns.includes(20120)) {
        links.push({
          icon: 'iconzhiling',
          text: this.$t('onlineMonitor.panelLink5')
        })
      }

      // if (this.$i18n.locale === 'zh' && this.currentHoldId === 1) {
      //   links.push({icon:'iconfasongxiaoxi', text:this.$t('onlineMonitor.panelLink7')})
      // }

      // if (this.loginInfo.allFuns.includes(20070)) {
      //   // links.push({icon:'icon-jiance', text:this.$t('onlineMonitor.panelLink6')})
      //   links.push({
      //     icon: 'iconzhaochangtingdian',
      //     text: this.$t('route.riskPlace')
      //   })
      // }

      let onPanelUpdate = () => {}
      if (this.currentMap == 'Bmap') {
        onPanelUpdate = () => {
          this.infoWindow.open(this.map, [device.Lon, device.Lat])
        }
      }
      let ObjectID = device.ObjectID
      console.log(device)
      let panel = this.$infowindow.create({
        VehicleName: device.VehicleName,
        personName: device.PersonName,
        ArtName: device.ArtName,
        VehicleID: device.VehicleID,
        TypeID: device.TypeID,
        Temperature: device.Temperature,
        objectId: device.ObjectID,
        Speed: device.Speed,
        PaceSpeed: device.PaceSpeed,
        headClick: true,
        bgColor: true,
        titleBackground,
        lon: device.Lon,
        lat: device.Lat,
        TrackerType: device.TrackerType,
        showWifiInfo: true,
        SF: device.SF,
        GSM: device.GSM,
        GPS: device.GPS,
        Mileage: device.Mileage,
        BattertLife:device.BattertLife,
        Altitude:device.Altitude,
        ToDayMile: device.ToDayMile,
        isFocus: device.isFocus,
        showHeadButton: false,
        showAddrDefault: true,
        PicPath:device.PicPath?device.PicPath.split("|") : [],
        showDvr: device.MDTModelID === '3169' && !device.soures ? true : false,
        dvrData: device.VideoCircuit ? device.VideoCircuit.split(",") : [],
        onAddressClick: id => this.panelLinkClick(this.$t('onlineMonitor.panelLink1'), null, id),
        disabledAddress: true,
        hideOriginGps: true,
        onAddressUpdate: onPanelUpdate,
        gpsLabelDefault: this.$t('commonPanel.satelLocation'),
        title: device.ObjectName + (device.VehicleList.length > 0 ? '<i class="el-icon-caret-bottom"/>' : null),
        datas: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
        ],
        personnelData: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
          {
            title: `personInfo`,
            text: `${device.PersonNam?device.PersonName+';':''}${device.Age?device.Age+';':''}${device.Sex===1?this.$t('onlineMonitor.uMale'):this.$t('onlineMonitor.uFemale')}`
          }
        ],
        objectData: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
          {
            title: `assetInfo`,
            text: `${device.ArtName?device.ArtName+';':''}${device.ArtNo?device.ArtNo+';':''}${device.Weight?device.Weight+ this.$t('deviceDetail.Unit2')+';':''}${device.Length + "cm*"}${device.Width + "cm*"}${device.Height + "cm"}`
          }
        ],
        gtime: miment(device.GPSTime).format(),
        rtime: miment(device.RcvTime).format(),
        links,
        onLinkClick: (item, e) => this.panelLinkClick(item, e, ObjectID),
        onHeadClick: (e, objectId) => this.panelHeadClick(e, objectId),
        onHeadButtonClick: () => this.panelHeadButtonClick(ObjectID),
        videoURL: (e) => this.panelVideoClick(e, device),
        isAlarm: device.isAlarm,
        onCancelAlarm: this.handleCancelAlarm,
        paddingLeft: '5em',
        showMore: false,
        externalData: externalData,
        close: true,
        //visible: this.panel_open,
        onClose: () => {
          this.google ? this.infoWindow.close() : this.map.clearInfoWindow()
        },
        onChange: () => {}
      })
      return panel
    },
    addDataToMap_getPanel_gl(device) {
      // 如果在监控列表里面就取最新数据
      const index = this.list.findIndex(k => this.diffCompare(k, device))
      if (~index) {
        device = this.list[index].data
      }
      // 判断是有线设备还是无线设备
      let externalData = null
      if (device.TrackerType !== 0 || device.Battery > 0) {
        // 无线设备
        externalData = {
          trackerType: device.TrackerType,
          battery: device.Battery,
          batteryLife: device.BatteryLife,
          schedule: localTime(device.Schedule)
        }
      }
      let replceStatus = ''
      if (device.StatusDes.search(device.Schedule) > 0) {
        replceStatus = device.StatusDes.replace(new RegExp(device.Schedule, 'g'), localTime(device.Schedule))
      }
      if (this.$i18n.locale === 'fr') {
        replceStatus = translateFR(device.StatusDes)
      }
      let colorArr = ['#65c240', '#1469b8', '#ff4141', '#7f7f7f', '#ffd800', '#7f03d8']
      let titleBackground = colorArr[translateGps(device.TypeID, device, true) - 1]
      // 1：行驶2.熄火 3.静止/怠速 4.离线 5.报警 6.无参考定位

      let viewInfoFun = this.loginInfo.allFuns.includes(5042) || this.loginInfo.allFuns.includes(5044)
      let isVipImg = device.isVip ? `<img src="${require('../../assets/icon/vip.png')}" style="margin-left:8px;height:14px;vertical-align:sub;"/>` : ''

      let links = []

      if (viewInfoFun && this.importantInfo) {
        links.push({
          icon: 'iconxiangqing',
          text: this.$t('onlineMonitor.panelLink2')
        })
      }

      {
        links.push({
          icon: 'iconquanjing',
          text: this.$t('onlineMonitor.panelLink3'),
          lang: true
        })

        links.push({
          icon: 'iconguiji',
          text: this.$t('onlineMonitor.panelLink4')
        })

        links.push({
          icon: 'iconzhuizong1',
          text: this.$t('onlineMonitor.panelLink12')
        }) //定位
      }

      if (this.importantInfo) {
        if (device.SF) {
          links.push({
            icon: 'iconshefang',
            text: this.$t('onlineMonitor.panelLink9')
          })
        } else {
          links.push({
            icon: 'iconchefang',
            text: this.$t('onlineMonitor.panelLink8')
          })
        }
      }

      if (device.isFocus) {
        links.push({
          icon: 'iconyishoucang',
          text: this.$t('onlineMonitor.panelLink11')
        })
      } else {
        links.push({
          icon: 'iconshoucang',
          text: this.$t('onlineMonitor.panelLink10')
        })
      }

      if (this.loginInfo.isUser && this.importantInfo && this.loginInfo.allFuns.includes(20120)) {
        links.push({
          icon: 'iconzhiling',
          text: this.$t('onlineMonitor.panelLink5')
        })
      }

      // if (this.$i18n.locale === 'zh' && this.currentHoldId === 1) {
      //   links.push({icon:'iconfasongxiaoxi', text:this.$t('onlineMonitor.panelLink7')})
      // }

      if (this.loginInfo.allFuns.includes(20070)) {
        // links.push({icon:'icon-jiance', text:this.$t('onlineMonitor.panelLink6')})
        links.push({
          icon: 'iconzhaochangtingdian',
          text: this.$t('route.riskPlace')
        })
      }

      let onPanelUpdate = () => {}
      // if (this.currentMap == 'Bmap') {
      //   onPanelUpdate = () => {
      //     this.infoWindow.open(this.map, [device.Lon, device.Lat])
      //   }
      // }
      let ObjectID = device.ObjectID
      let panel = this.$infowindow.create({
        isGl: false,
        VehicleName: device.VehicleName,
        personName: device.PersonName,
        ArtName: device.ArtName,
        VehicleID: device.VehicleID,
        TypeID: device.TypeID,
        Temperature: device.Temperature,
        objectId: device.ObjectID,
        Speed: device.Speed,
        PaceSpeed: device.PaceSpeed,
        headClick: true,
        bgColor: true,
        titleBackground,
        lon: device.Lon,
        lat: device.Lat,
        TrackerType: device.TrackerType,
        showWifiInfo: true,
        SF: device.SF,
        GSM: device.GSM,
        GPS: device.GPS,
        Mileage: device.Mileage,
        ToDayMile: device.ToDayMile,
        BattertLife:device.BattertLife,
        Altitude:device.Altitude,
        isFocus: device.isFocus,
        showHeadButton: false,
        showAddrDefault: true,
        showDvr: device.MDTModelID === '3169' && !device.soures ? true : false,
        dvrData: device.VideoCircuit ? device.VideoCircuit.split(",") : [],
        onAddressClick: id => this.panelLinkClick(this.$t('onlineMonitor.panelLink1'), null, id),
        disabledAddress: true,
        hideOriginGps: true,
        onAddressUpdate: onPanelUpdate,
        gpsLabelDefault: this.$t('commonPanel.satelLocation'),
        title: device.ObjectName + (device.VehicleList.length > 0 ? '<i class="el-icon-caret-bottom"/>' : null),
        datas: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
        ],
        personnelData: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
          {
            title: `personInfo`,
            text: `${device.PersonNam?device.PersonName+';':''}${device.Age?device.Age+';':''}${device.Sex===1?this.$t('onlineMonitor.uMale'):this.$t('onlineMonitor.uFemale')}`
          }
        ],
        objectData: [{
            title: 'deviceID',
            text: `${device.SIM2}-${device.MDTModelName.trimLeft().replace('VDF-', '')}${device.TrackerType===0?this.$t('onlineMonitor.haslineDevice'):this.$t('onlineMonitor.nolineDevice')}${isVipImg}`,
            rawHtml: true
          },
          {
            title: 'gpsTime',
            text: miment(device.GPSTime).format(),
            hidden: true
          },
          {
            title: 'lastLocation',
            text: miment(device.RcvTime).format()
          },
          {
            title: `statsDes`,
            text: replceStatus ? replceStatus : device.StatusDes,
            modelId: `${device.MDTModelID}`
          },
          {
            title: `assetInfo`,
            text: `${device.ArtName?device.ArtName+';':''}${device.ArtNo?device.ArtNo+';':''}${device.Weight?device.Weight+ this.$t('deviceDetail.Unit2')+';':''}${device.Length + "cm*"}${device.Width + "cm*"}${device.Height + "cm"}`
          }
        ],
        gtime: miment(device.GPSTime).format(),
        rtime: miment(device.RcvTime).format(),
        links,
        onLinkClick: (item, e) => this.panelLinkClick(item, e, ObjectID),
        onHeadClick: (e, objectId) => this.panelHeadClick(e, objectId),
        onHeadButtonClick: () => this.panelHeadButtonClick(ObjectID),
        videoURL: (e) => this.panelVideoClick(e, device),
        isAlarm: device.isAlarm,
        onCancelAlarm: this.handleCancelAlarm,
        paddingLeft: '5em',
        showMore: false,
        externalData: externalData,
        close: true,
        //visible: this.panel_open,
        onClose: () => {
          this.infoWindow.close()
        },
        onChange: () => {}
      })
      return panel
    },
    panelLinkClick(name, e, ObjectID) {
      let device = this.getDataFromTable(ObjectID)
      if (name === this.$t('onlineMonitor.panelLink4')) {
        this.$router.push(`/online/track?id=${device.ObjectID}`)
      }
      if (name === this.$t('onlineMonitor.panelLink12')) {
        this.$router.push(`/online/tracing?id=${device.ObjectID}`)
      }
      if (name === this.$t('route.riskPlace')) {
        this.$router.push(`/risk/place?id=${device.ObjectID}`)
      }
      if (name === this.$t('onlineMonitor.panelLink2')) {
        if (device.TypeID === 1) {
          this.$refs.detailPerson.open(device.ObjectID)
        } else if (device.TypeID === 2) {
          this.$refs.detailObject.open(device.ObjectID)
        } else {
          this.$refs.detail.open(device.ObjectID)
        }
      }
      if (name === this.$t('onlineMonitor.panelLink5')) {
        this.checkedObjectKeysDefault = [device.ObjectID]
        this.currentCommandDefault = device.MDTTypeID || 10
        this.currentCommandTracker = device.TrackerType || 0
        this.isShowDialogBatch = true
        this.isShowDialogBatchReal = true
        this.currentCommandSingle = false
        this.deviceInfo = device
      }
      if (name === this.$t('onlineMonitor.panelLink3')) {
        this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
      }
      if (name === this.$t('onlineMonitor.panelLink14')) {
        this.clickDialogTire(device)
      }
      if (name === this.$t('onlineMonitor.panelLink1')) {
        API.GetWIFIAndLBSInfoByObjectID(device.ObjectID).then(ret => {
          if (!ret.data.cell.points.length && !ret.data.wifi.points.length) {
            this.$alert(this.$t('onlineMonitor.noLbsTip'))
            return
          }

          this.$refs.dialogCellAndLbs.open(Object.assign(ret.data, {
            VehicleImageUrl: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            Lon: ret.data.gps.center.Lng,
            Lat: ret.data.gps.center.Lat,
            Direct: device.Direct
          }), miment(device.GPSTime).format(), miment(device.RcvTime).format(), ret.data.type)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink7')) {
        this.sendMessage(device.SIM)
      }
      if (name === this.$t('onlineMonitor.panelLink8')) {
        API.ModifySF(device.ObjectID, 1).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('onlineMonitor.setSF')
          })
          this.addOneDevice(device)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink9')) {
        API.ModifySF(device.ObjectID, 0).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('onlineMonitor.cancelSF')
          })

          this.addOneDevice(device)
        })
      }
      if (name === this.$t('onlineMonitor.panelLink10')) {
        AddStar(device.VehicleID, device.TypeID).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('commonListVehicle.followStatus')
          })
          this.addTaskCar(device.ObjectID)
          this.addOneDevice(device)
        })

      }
      if (name === this.$t('onlineMonitor.panelLink11')) {
        DeleteStar(device.VehicleID, device.TypeID).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('commonListVehicle.followStatus2')
          })
          this.delTaskCar(device.ObjectID)
          this.addOneDevice(device)
        })
      }
    },
    panelHeadClick(e, objectId) {
      let device = this.getDataFromTable(objectId)
      this.panelContext.top = e.target.getBoundingClientRect().bottom
      this.panelContext.left = e.target.getBoundingClientRect().left
      this.panelContext.selectObjectId = objectId
      this.panelContext.objectIdList = device.VehicleList
      this.panelContext.visible = true
    },
    panelHeadButtonClick(ObjectID) {
      let device = this.getDataFromTable(ObjectID)
      this.addTaskCar(device.ObjectID)
    },
    panelVideoClick(index, data) {
      this.openVideo(index, data)
    },
    getDataFromTable(ObjectID) {
      let index = this.list.findIndex(k => this.diffCompare(k, {
        ObjectID
      }))
      return this.list[index].data
    },
    handleCancelAlarm(ObjectID) {
      CancelAlarm(this, ObjectID)
        .then(() => {
          let device = this.getDataFromTable(ObjectID)
          this.currentMap === 'Bmap' ? this.updataInfowindowByData_bd(device) : this.updataInfowindowByData(device)
          // this.addOneDevice(device,true,true)
        })
        .catch(() => {})
    },
    carMarkerClick(e) {
      let data = this.getDataFromTable(e.target.getExtData())
      let panel = this.addDataToMap_getPanel(data)
      this.infoWindow.setContent(panel)
      this.infoWindow.open(this.map, e.lnglat)
      this.infoWindow.setId(e.target.getExtData())
    },
    getIconAngle(imageUrl, angle) {
      if (imageUrl.indexOf('Alarm') != -1 ||
        imageUrl.indexOf('Personal') != -1 ||
        imageUrl.indexOf('hat') != -1 ||
        imageUrl.indexOf('RV') != -1 ||
        imageUrl.indexOf('Fifth Wheel') != -1 ||
        imageUrl.indexOf('person') != -1 ||
        imageUrl.indexOf('article') != -1 ||
        imageUrl.indexOf('Trailer') != -1 ||
        imageUrl.indexOf('child') != -1 ||
        imageUrl.indexOf('youth') != -1 ||
        imageUrl.indexOf('middle-aged') != -1 ||
        imageUrl.indexOf('old-man') != -1 ||
        imageUrl.indexOf('other') != -1 ||
        imageUrl.indexOf('child1') != -1 ||
        imageUrl.indexOf('youth1') != -1 ||
        imageUrl.indexOf('middle-aged1') != -1 ||
        imageUrl.indexOf('old-man1') != -1 ||
        imageUrl.indexOf('other1') != -1 ||
        imageUrl.indexOf('child0') != -1 ||
        imageUrl.indexOf('youth0') != -1 ||
        imageUrl.indexOf('middle-aged0') != -1 ||
        imageUrl.indexOf('old-man0') != -1 ||
        imageUrl.indexOf('other0') != -1 ||
        imageUrl.indexOf('cuboid') != -1 ||
        imageUrl.indexOf('round-ellipse') != -1 ||
        imageUrl.indexOf('irregular-form') != -1 ||
        imageUrl.indexOf('Moto') != -1 ||
        imageUrl.indexOf('Excavator') != -1 ||
        imageUrl.indexOf('gs_A+0') != -1 ||
        imageUrl.indexOf('gs_A+1') != -1 ||
        imageUrl.indexOf('gs_A0') != -1 ||
        imageUrl.indexOf('gs_A1') != -1 ||
        imageUrl.indexOf('gs_image') != -1 ||
        imageUrl.indexOf('gs_media') != -1 ||
        imageUrl.indexOf('gs_other0') != -1 ||
        imageUrl.indexOf('gs_other1') != -1 ||
        imageUrl.indexOf('gs_rescue') != -1 ||
        imageUrl.indexOf('gs_shuttlebus') != -1 ||
        imageUrl.indexOf('Marine') != -1) {
        return 0
      } else {
        return angle || 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasMenu',
      'monitorLeft'
    ])
  },
  components: {
    DetailVehicle,
    MapPlace,
    DetailDevice
  },
  beforeDestroy() {
    clearInterval(window.countdown_timer)
    if (this.map && this.map.destroy) {
      this.map.destroy()
    }
  }
}
